<template>
  <div>
    <b-alert variant="light" show>
      <div v-if="$i18n.locale === 'tr'" class="alert-heading bg-primary bg-lighten-1 text-white rounded">
        <div v-if="isMobile" class="mb-1">
          <b-card-text class="mb-0 mr-1 d-inline">
            {{ activeCheck == true ? $t('Active Packages') : $t('Consumed Packages') }}
          </b-card-text>
          <b-form-checkbox
              v-model="activeCheck"
              :class="activeCheck ? 'custom-control-success d-inline' : 'custom-control-danger d-inline'"
              name="check-button"
              switch
          >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon"/>
        </span>
            <span class="switch-icon-right">
          <feather-icon icon="XIcon"/>
        </span>
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-between align-items-between">
          <div>
            <b> {{headerText}}</b>
          </div>
        <div>
            <b-card-text class="mb-0 mr-1 d-inline">
              {{ activeCheck == true ? $t('Active Packages') : $t('Consumed Packages') }}
            </b-card-text>
            <b-form-checkbox
                v-model="activeCheck"
                :class="activeCheck ? 'custom-control-success d-inline' : 'custom-control-danger d-inline'"
                name="check-button"
                switch >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon"/>
          </span>
              <span class="switch-icon-right">
          <feather-icon icon="XIcon"/>
          </span>
              </b-form-checkbox>
          </div>
        </div>
      </div>
      <div v-if="$i18n.locale === 'en'" class="alert-heading bg-primary bg-lighten-1 text-white rounded">
        {{CustomerProductDetailActiveCards.length}} active products found that you can make an appointment
      </div>
    </b-alert>

    <b-row v-if="(CustomerProductDetailActiveCards.length > 0 && activeCheck== true)">
      <b-col
        v-for="CustomerProductDetailCard in CustomerProductDetailActiveCards"
        :key="CustomerProductDetailCard.trainer_product_id"
        :class=" 2 ? 'd-block p-1' : 'd-none'" md="4" sm="6">
        <SelectProductCardActive :CustomerProductDetailCard="CustomerProductDetailCard"/>
      </b-col>
    </b-row>
    <b-row v-else-if="activeCheck == true">
        <b-col>
          <template >
            <b-row class="d-flex justify-content-center align-items-center text-center">
              <p class="make-appointment-title">{{$t('You Do Not Have Any Products To Make An Appointment')}}</p>
            </b-row>
            <b-row class="d-flex justify-content-center align-items-center">
              <b-img
            class="select-product-svg"
            src="@/assets/images/badge/buy-product.svg" fluid></b-img>
            </b-row>
            <b-row class="d-flex justify-content-center">
            <b-button
            variant="primary" class="my-1 d-block"
            :to="{name: 'shop'}">{{$t('Buy Product')}}</b-button>
          </b-row>
        </template>
      </b-col>
    </b-row>

     <b-row v-if="(CustomerProductDetailConsumedCards.length > 0 && activeCheck == false)">
      <b-col
        v-for="CustomerProductDetailCard in CustomerProductDetailConsumedCards"
        :key="CustomerProductDetailCard.trainer_product_id"
        :class=" 2 ? 'd-block p-1' : 'd-none'" md="4" sm="6">
        <SelectProductCardNotActive :CustomerProductDetailCard="CustomerProductDetailCard"/>
      </b-col>
    </b-row> 
    <b-row v-else-if="activeCheck== false">
      <b-col>
        <template>
            <b-row class="d-flex justify-content-center align-items-center text-center">
              <p class="make-appointment-title">{{$t('You Have No Consumed Product')}}</p>
            </b-row>
            <b-row class="d-flex justify-content-center align-items-center">
              <b-img
            class="select-product-svg"
            src="@/assets/images/badge/buy-product.svg"  fluid></b-img>
            </b-row>
            <b-row class="d-flex justify-content-center">
            </b-row>
        </template>
      </b-col>
    </b-row> 
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import { turkey } from 'flatpickr/dist/l10n/tr.js'
import MoneyFormat from '@/functions/MoneyFormat'
import {
  BAlert,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardCode,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSpinbutton,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPopover,
  BRow,
  BVToastPlugin
} from 'bootstrap-vue'

import SelectProductCardActive from '@/components/SelectProductCards/SelectActiveProductCard'
import SelectProductCardNotActive from '@/components/SelectProductCards/SelectNotActiveProductCard'

export default {
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: "",
        mobile: "",
        houseNo: "",
        landmark: "",
        city: "",
        pincode: "",
        state: "",
        addressType: null,
      },
      payment: {
        cvv: "",
      },
    });
    return {
      refFormWizard,
      formWizardNextStep,
      // Dummy Details
      checkoutDetails,
    };
  },
  components: {
    SelectProductCardActive,
    SelectProductCardNotActive,
    BCardBody,
    BLink,
    BBadge,
    BAvatar,
    BVToastPlugin,
    FormWizard,
    TabContent,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormSelect,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardText,
    BCardCode,
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    flatPickr,
    BMediaAside,
    BAvatarGroup,
    BMediaBody,
    FormWizard,
    TabContent,
    BFormSpinbutton,
    BAlert,
    BFormCheckbox,
    BMediaAside,
    BPopover
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeCheck:true,     
      productLoaded : false,
      CustomerProductDetailActiveCards:[],
      CustomerProductDetailConsumedCards:[],
      productCount:0,
      headerText:this.$t('Your current appointment packages')

    };
  },

  methods: {
    getCustomerProductsForSelect() {
      this.$database.CustomerProductSaleService.GetCustomerProductDetails(
        this.$store.getters["auth/userInfo"].person_id,
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {
          
        this.productLoaded = true
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          res.Result.map((el) => {
            if(el.IsConsumed == true)
            {
              this.CustomerProductDetailActiveCards.push(this.$models.customerProductCardView(
                el.ActivityDetailCardView,
                el.CustomerProductCardView,
                el.TrainerDetailCardView,
                el.TrainerProductCardView,
                el.TotalProductSession, 
                el.ConsumedProductSession,
                el.RemainingProductSession,
                el.IsConsumed
              ))
            }
            if(el.IsConsumed == false)
            {
              this.CustomerProductDetailConsumedCards.push(this.$models.customerProductCardView(
                el.ActivityDetailCardView,
                el.CustomerProductCardView,
                el.TrainerDetailCardView,
                el.TrainerProductCardView,
                el.TotalProductSession, 
                el.ConsumedProductSession,
                el.RemainingProductSession,
                el.IsConsumed
              ))
            }
          });

               console.log(this.CustomerProductDetailActiveCards)
               console.log(this.CustomerProductDetailConsumedCards)
              
              // if(this.activeCheck == true)
              // this.productCount = this.CustomerProductDetailActiveCards.length;
              // if(this.activeCheck == false)
              // this.productCount = this.CustomerProductDetailConsumedCards.length
        }
      });
    }
  },
  computed: {
    
  },
  watch: {
    activeCheck(val) {
      if(val==true)
        {
          this.headerText=this.$t('Your current appointment packages')
          this.productCount= this.CustomerProductDetailActiveCards.length;
        }
      
      if(val== false)
      {
          this.headerText=this.$t('Your consumed appointment packages')
          this.productCount=this.CustomerProductDetailConsumedCards.length
      }

    },
  },
  // validations: {},
  created() {
    // this.studio_id = this.$route.params.studioID
    this.companyBranchId =
      this.$store.getters["auth/userInfo"].company_branch_id;
    this.getCustomerProductsForSelect();

   
  },
};
</script>

<style scoped src="@/styles/css/selectProduct.css"> </style>
<style lang="scss" src="@/styles/scss/selectProduct.scss"> </style>
