<!-- Randevu Almadan Önce Ürün Seçmek İçin Kullanılacak Card Bileşeni -->
<template>
  <b-card class="earnings-card custom-shadow product-card h-100">
    <b-row cols="12" class="bg-primary rounded text-white custom-shadow">
      <b-col class="d-flex justify-content-center">
        <p class="font-small-3 mt-1">
            {{CustomerProductDetailCard.TrainerProductCardView.trainer_product_title}}
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="6">
        <div class="mt-1">
          <span class="font-small-3"><b>{{ $t('Trainer') }}:</b> <span class="font-small-3"> {{
            CustomerProductDetailCard.TrainerDetailCardView.trainer_first_name
            }}</span></span>
        </div>
        <div>
          <span class="font-small-3"><b>{{ $t('Activity') }}:</b><span class="font-small-3"> {{
            CustomerProductDetailCard.ActivityDetailCardView.activity_name
            }}</span></span>
        </div>
         <b-button
            :to="{
              name: 'makeAppointment',
              params: {
                companyBranchID: CustomerProductDetailCard.CustomerProductCardView.company_branch_id,
                trainerID: CustomerProductDetailCard.TrainerDetailCardView.trainer_id,
                activityID: CustomerProductDetailCard.ActivityDetailCardView.activity_id,
                trainerProductID: CustomerProductDetailCard.TrainerProductCardView.trainer_product_id,
                customerProductSalesID: CustomerProductDetailCard.CustomerProductCardView.customer_product_sales_id
              },
            }"
            block
            variant="primary"
            class="mt-1 select-button"
        >{{ $t('Select') }}
        </b-button>
        <b-card-text class="text-muted font-small-2 mt-2">
          <b-badge pill :variant="CustomerProductDetailCard.ConsumedProductSession < CustomerProductDetailCard.TotalProductSession ? 'success' : 'warning'"
            class="pl-1 pr-1 badge-glow">{{ CustomerProductDetailCard.ConsumedProductSession }}/{{ CustomerProductDetailCard.TotalProductSession }}
          </b-badge>
        </b-card-text>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end align-items-center">
        <div class="profile-image">
          <b-avatar  size=125   variant="light" :src="CustomerProductDetailCard.TrainerProductCardView.trainer_product_image" />
        </div>
        </b-col>
    </b-row>
    <!-- <b-card-text
        :id="product.customer_product_sales_id"
        class="text-muted font-small-3 mt-0 p-0 float-right">
      <b style="text-decoration: underline;">{{ $t('Details') }}</b>
    </b-card-text> -->
    <!-- <b-popover
        :target="product.customer_product_sales_id" placement="top" triggers="click">
      <template #title>
        {{ $t('Consumption Dates') }}
      </template>
      <div v-if="getCount(product.trainer_product_id, product.customer_product_sales_id).dataArray.length > 0">
        <p v-for="(item,index) in getCount(product.trainer_product_id, product.customer_product_sales_id).dataArray"
           :key="index">
          <span>{{ getDateFormat(item.created_date) }}</span>
        </p>
      </div>
      <div v-else>
        {{ $t('No Consumption') }}
      </div>
    </b-popover>  -->
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSpinbutton,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPopover,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'SelectActiveProductCard',
  components: {
    BCardBody,
    BLink,
    BBadge,
    BAvatar,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormSelect,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BAvatarGroup,
    BMediaBody,
    BFormSpinbutton,
    BAlert,
    BFormCheckbox,
    BMediaAside,
    BPopover
  },
  props: {

    CustomerProductDetailCard:{
      type:Object,
      required:true
    },
  },
  methods: {
    getCount(trainer_product_id, customer_product_sales_id) {
      let count = 0
      let dataArray = []
      //time table dan gelen müşteri kayıtları ile alınan ürün kayıtlarının product id lerini karşılaştırırım
      this.timeTableDetails.map(el => {
        if (el.trainer_product_id == trainer_product_id
            &&
            el.customer_product_sales_id == customer_product_sales_id) {
          dataArray.push(el)
          count++
        }
      })
      return {
        count,
        dataArray
      }
    }
    ,
    getDateFormat(date) {
      return this.$functions.DateFormatAndTime(date)
    }
  },
  computed: {
    moment(){
      return new Date()
    }
  },
}
</script>
