var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"earnings-card custom-shadow product-card h-100"},[_c('b-row',{staticClass:"bg-primary rounded text-white custom-shadow",attrs:{"cols":"12"}},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"font-small-3 mt-1"},[_vm._v(" "+_vm._s(_vm.CustomerProductDetailCard.TrainerProductCardView.trainer_product_title)+" ")])])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"font-small-3"},[_c('b',[_vm._v(_vm._s(_vm.$t('Trainer'))+":")]),_vm._v(" "),_c('span',{staticClass:"font-small-3"},[_vm._v(" "+_vm._s(_vm.CustomerProductDetailCard.TrainerDetailCardView.trainer_first_name))])])]),_c('div',[_c('span',{staticClass:"font-small-3"},[_c('b',[_vm._v(_vm._s(_vm.$t('Activity'))+":")]),_c('span',{staticClass:"font-small-3"},[_vm._v(" "+_vm._s(_vm.CustomerProductDetailCard.ActivityDetailCardView.activity_name))])])]),_c('b-button',{staticClass:"mt-1 select-button",attrs:{"to":{
            name: 'makeAppointment',
            params: {
              companyBranchID: _vm.CustomerProductDetailCard.CustomerProductCardView.company_branch_id,
              trainerID: _vm.CustomerProductDetailCard.TrainerDetailCardView.trainer_id,
              activityID: _vm.CustomerProductDetailCard.ActivityDetailCardView.activity_id,
              trainerProductID: _vm.CustomerProductDetailCard.TrainerProductCardView.trainer_product_id,
              customerProductSalesID: _vm.CustomerProductDetailCard.CustomerProductCardView.customer_product_sales_id
            },
          },"block":"","variant":"primary"}},[_vm._v(_vm._s(_vm.$t('Select'))+" ")]),_c('b-card-text',{staticClass:"text-muted font-small-2 mt-2"},[_c('b-badge',{staticClass:"pl-1 pr-1 badge-glow",attrs:{"pill":"","variant":_vm.CustomerProductDetailCard.ConsumedProductSession < _vm.CustomerProductDetailCard.TotalProductSession ? 'success' : 'warning'}},[_vm._v(_vm._s(_vm.CustomerProductDetailCard.ConsumedProductSession)+"/"+_vm._s(_vm.CustomerProductDetailCard.TotalProductSession)+" ")])],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"profile-image"},[_c('b-avatar',{attrs:{"size":"125","variant":"light","src":_vm.CustomerProductDetailCard.TrainerProductCardView.trainer_product_image}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }